<style scoped>
	.add_dialog_box {
		width: 100%;height: 100%;display: flex;flex-direction: column;}
	.add_dialog_box_body {
		flex: 1;}
	.add_dialog_box_body_container {
		height: 100%;width: 100%;overflow-y: auto;}
	.M_form_box{
		height: 100%;display: flex;flex-direction: column;}
	.M_editer{
		flex: 1;}
	.M_editer .el-row,.M_editer .el-col{
		height: 100%;}
	.M_editer .el-col{
		display: flex;flex-direction: column;}
	.M_editer .editer_container{
		flex: 1;}
	.build_select {
		margin: 20px 0;}
	.add_dialog_box_footer{
		text-align: right;padding-top: 5px;}
</style>
<template>

	<div class="add_dialog_box" v-loading="uploadLoading" element-loading-text="上传中" element-loading-spinner="el-icon-loading"
	 element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="add_dialog_box_header"></div>
		<div class="add_dialog_box_body">
			<div class="add_dialog_box_body_container">
				<div class="M_form_box">
					<div class="M_form_box_row">
						<el-row>
							<el-col :span="12">
								<el-input placeholder="请输入标题" v-model="title">
									<template slot="prepend">
										<div class="M_inpu_label">通知标题</div>
									</template>
								</el-input>
							</el-col>
						</el-row>
					</div>
					<div class="M_form_box_row build_select">
						<el-row>
							<el-col :span="24">
								<span style="padding-right: 30px;">请选择楼宇：</span>
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
								<div style="margin: 15px 0;"></div>
								<el-checkbox-group v-model="checkedbuilds" @change="handleCheckedbuildsChange">
									<el-checkbox v-for="item in builds" :label="item" :value="item.id">{{item.name}}</el-checkbox>
								</el-checkbox-group>
							</el-col>
						</el-row>
					</div>
					<div class="M_form_box_row M_editer">
						<el-row>
							<el-col :span="24">
								<div class="editer_label">
									<span style="padding-right: 30px;">编辑内容：</span>
									<div style="margin: 15px 0;"></div>
								</div>
								<div class="editer_container" id="editer_container" style="overflow: hidden;">
									<div class="M_editer_container" :style="{height:quillEditorHeight+'px'}">
										<quill-editor v-model="content" :options="editorOption" ref="QuillEditor" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
										 @change="onEditorChange($event)">
										</quill-editor>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<input hidden="hidden" id="file" @change="handleFileChange" type="file" name="file" multiple="multiple"></input>
		</div>
		<div class="add_dialog_box_footer">
			<el-button @click="closeDialog">取消</el-button>
			<el-button type="success" @click="api_save">立即发布</el-button>
		</div>
	</div>
</template>

<script>
	// import Tinymce from "@/components/_Acommon/Tinymce";
	// 富文本工具栏配置
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		['blockquote', 'code-block'],
		[{
			'header': 1
		}, {
			'header': 2
		}], // custom button values
		[{
			'list': 'ordered'
		}, {
			'list': 'bullet'
		}],
		[{
			'script': 'sub'
		}, {
			'script': 'super'
		}], // superscript/subscript
		[{
			'indent': '-1'
		}, {
			'indent': '+1'
		}], // outdent/indent
		[{
			'direction': 'rtl'
		}], // text direction
		[{
			'size': ['small', false, 'large', 'huge']
		}], // custom dropdown
		[{
			'header': [1, 2, 3, 4, 5, 6, false]
		}],
		[{
			'color': []
		}, {
			'background': []
		}], // dropdown with defaults from theme
		[{
			'font': []
		}],
		[{
			'align': []
		}],
		['link', 'image', 'video'],
		['clean'] // remove formatting button
	];
	import {
		Quill
	} from 'vue-quill-editor'

	var _this;
	export default {
		props: ['pageParams'],
		components: {},
		data() {
			return {
				quillEditorHeight: '',
				checkAll: false,
				builds: [], //楼宇列表
				checkedbuilds: [], //选择的楼宇
				isIndeterminate: true,
				title: '',
				content: '', // —— 富文本内容
				editorOption: {
					placeholder: '请编辑通知内容',
					modules: {
						toolbar: {
							container: toolbarOptions, // 工具栏
							handlers: {
								'image': function(value) {
									if (value) {
										// alert('自定义图片')
										document.getElementById('file').click();
									} else {
										this.quill.format('image', false);
									}
								}
							}
						}
					}
				},
				uploadLoading: false
			}
		},
		created: function() {
			_this = this;
			_this.api_getBuildData();
		},
		mounted: function() {
			_this.$nextTick(function() {
				var h = document.getElementById('editer_container').offsetHeight;
				_this.quillEditorHeight = h - 80;
				_this.content = _this.pageParams.content;
				_this.title = _this.pageParams.title;
			})
		},
		methods: {
			/** --------> 方法介绍: 关闭当前dialofg
			author:郑凯 创建时间:2021-1-25 11:50:23 */
			closeDialog() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},
			/** --------> 方法介绍: 获取楼宇列表
			author:郑凯 创建时间:2021-1-25 12:04:12 */
			api_getBuildData() {
				_this._getApi('/wy/residenceBuild/getList', {
					residence_id: _this.pageParams.residence_id
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						_this.builds = baseData;
						// 选择的楼宇
						var hasArrStr = _this.pageParams.build_ids;
						var hasArr = hasArrStr.split(',');
						var hasArrBase = [];
						baseData.forEach(function(item) {
							hasArr.forEach(function(item_1) {
								if(item.id == item_1){
									hasArrBase.push(item)
								}
							})
						})
						_this.checkedbuilds = hasArrBase;
						console.log(_this.checkedbuilds)
					}
				}).catch((err) => {});
			},
			// _ ******- 全选 
			handleCheckAllChange(val) {
				_this.checkedbuilds = val ? _this.builds : [];
				_this.isIndeterminate = false;
			},
			// _ ******- 选择框变化
			handleCheckedbuildsChange(value) {
				var checkedCount = value.length;
				this.checkAll = checkedCount === this.builds.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.builds.length;
			},
			// _ ******- 富文本事件
			// 准备编辑器
			onEditorReady(editor) {},
			// 失去焦点事件
			onEditorBlur() {},
			// 获得焦点事件
			onEditorFocus(val, editor) {

			},
			// 内容改变事件
			onEditorChange() {

			},

			/** --------> 方法介绍: 提交
			author:郑凯 创建时间:2021-1-25 12:04:53 */
			api_save() {
				var ids = [];
				_this.checkedbuilds.forEach(function(item) {
					ids.push(item.id);
				});
				var apiParams = {};
				apiParams.id = _this.pageParams.id;
				apiParams.title = _this.title;
				apiParams.content = _this.content;
				apiParams.build_ids = ids;
				apiParams.residence_id = _this.pageParams.residence_id;
				_this._postApi('/wy/notice/update', apiParams)
					.then((res) => {
						if (res.code == 1) {
							_this.$emit('childrenEvent', {
								type: 'successful'
							});
						} else {
							_this.$confirm(res.msg, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {

							}).catch(() => {

							});
						}
					}).catch((err) => {});
			},
			/** --------> 方法介绍: 选择图片
			author:郑凯 创建时间:2021-1-25 12:05:05 */
			handleFileChange(e) {
				var BlockEmbed = Quill.import('blots/block/embed')
				class ImageBlot extends BlockEmbed {
					static create(value) {
						let node = super.create();
						node.setAttribute('src', value.url);
						node.setAttribute('controls', value.controls)
						node.setAttribute('width', value.width)
						node.setAttribute('height', value.height)
						node.setAttribute('style', value.style);
						return node;
					}

					static value(node) {
						return {
							url: node.getAttribute('src'),
							controls: node.getAttribute('controls'),
							alt: node.getAttribute('style'),
						}
					}
				}
				ImageBlot.blotName = 'image';
				ImageBlot.tagName = 'img';

				Quill.register(ImageBlot);
				// 获取富文本组件实例
				var quill = _this.$refs.QuillEditor.quill;
				var files = e.target.files;
				if (files && files[0]) {
					var file = files[0];
					if (file.size > 1024 * 1024 * 50) {
						_this.$alert('图片大小不能超过50M', '提示', {
							confirmButtonText: '确定',
							callback: function(action) {}
						});
						return false;
					} else {
						_this.uploadLoading = true;
						var formData = new FormData();
						formData.append("file[]", file);
						_this._axios({
							method: 'post',
							url: '/wy/upload',
							headers: {
								"token": localStorage.getItem('token'),
								'Content-Type': 'multipart/form-data'
							},
							params: {},
							data: formData,
						}).then((response) => {
							var res = response.data;
							if (res.code == 1) {
								_this.uploadLoading = false;
								_this.$message({
									message: '上传成功！',
									type: 'success',
									duration: 1000,
									onClose: function() {}
								});
								// 获取光标所在位置
								var length = quill.getSelection().index;
								// 插入图片，res为服务器返回的图片链接地址
								quill.insertEmbed(length, 'image', {
									style: 'width:100%;display:block;',
									url: _this._IMAGE_URL + res.data.obj_key
								});
								// 调整光标到最后
								quill.setSelection(length + 1)
							} else {
								_this.btnSaveLoading = false;
								_this.$confirm(res.msg, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {

								}).catch(() => {

								});
							}
						}).catch(() => {

						});
					}
				}
			}
		}
	}
</script>
<!-- 汉化富文本 -->
<style>
	p {margin: 10px;}
	.edit_container,
	.quill-editor {height: 100%;}
	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {content: "14px";}
	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {content: "10px";}
	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {content: "18px";}
	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {content: "32px";}
	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {content: "文本";}
	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {content: "标题1";}
	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {content: "标题2";}
	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {content: "标题3";}
	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {content: "标题4";}
	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {content: "标题5";}
	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {content: "标题6";}
	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {content: "标准字体";}
	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {content: "衬线字体";}
	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {content: "等宽字体";}
</style>
