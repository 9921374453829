<style scoped>
	.out_box{width: 100%;height: 100%;}
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;position: relative;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
</style>
<style>
	table{box-sizing: border-box;}
	.no_ .el-table__body tr td{padding: 3px 0;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{background-color: #67CD80 !important;color: #fff;}
	.el-table__body tr.current-row>td.con_box{background-color: #FFF !important;color: #fff;}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
	.el-dialog.my_dialog.my_ .el-dialog__header{padding: 0!important;}
</style>
<template>
	<div class="out_box">
		<div class="tpm_box">
			<div class="tem_header">
				<div class="mb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>{{pageParams.name}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<el-button type="warning" size="small" @click="addBuild">发布<i class="el-icon-circle-plus-outline"></i></el-button>
				<el-button type="warning" size="small" @click="editBuild">编辑<i class="el-icon-edit"></i></el-button>
				<el-button type="warning" size="small" @click="deleteBuild">删除<i class="el-icon-delete"></i></el-button>
				<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="tem_body" id="tem_body">
				<div class="tem_body_contair no_" id="table_box" v-loading="tableLoading">
					<el-table :data="tableData" :height="tableHeight" border @row-click="rowClick" highlight-current-row :span-method="objectSpanMethod">
						<el-table-column prop="title" label="标题" width=""></el-table-column>
						<el-table-column prop="build_names" label="楼宇" width="180"></el-table-column>
						<el-table-column prop="" label="时间" width="">
							<template slot-scope="scope">
								<p>发布时间：{{scope.row.create_time}}</p>
								<p>更新时间：{{scope.row.update_time}}</p>
							</template>
						</el-table-column>
						<el-table-column prop="content" label="内容" width="120" class-name="con_box">
							<template slot-scope="scope">
								<el-button type="text" @click="seeInfo(scope.row)">查看详情</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="tem_footer">
				<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
				 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
				</el-pagination>
			</div>

			<!-- 发布 -->
			<el-dialog custom-class="my_dialog" width="30%" top="none" title="发布新消息" :visible.sync="addDialog" destroy-on-close
			 fullscreen :close-on-click-modal="false">
				<add v-if="addDialog" :pageParams="pageParams" v-on:childrenEvent="addEvent"></add>
			</el-dialog>
			
			<!-- 编辑 -->
			<el-dialog custom-class="my_dialog" width="30%" top="none" title="" :visible.sync="editDialog" destroy-on-close
			 fullscreen :close-on-click-modal="false" >
				<edit v-if="editDialog" :pageParams="infopageParams" v-on:childrenEvent="editEvent"></edit>
			</el-dialog>
			
			<!-- 详情 -->
			<el-dialog custom-class="my_dialog my_" width="30%" top="none" title="" :visible.sync="infoDialog" destroy-on-close
			 fullscreen :close-on-click-modal="false" :show-close="false">
				<info v-if="infoDialog" :pageParams="infopageParams" v-on:childrenEvent="infoEvent"></info>
			</el-dialog>
		</div>
		
	</div>
</template>

<script>
	import add from './add.vue';
	import info from './info.vue';
	import edit from './edit.vue';
	var _this;
	export default {
		components: {
			add,
			info,edit
		},
		props: ['communityId', 'buildPageParams'],
		data() {
			return {
				pageParams: null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				selectTableRow: null, // 选中的表格某行
				addDialog: false,
				infoDialog: false,
				infopageParams: null,
				editDialog:false,
			}
		},
		created: function() {
			_this = this;
			_this.pageParams = _this.buildPageParams;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			
			/* * 刷新 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			},
			/* * 切换分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			/* * 获取表格数据 */
			api_getTableData() {
				_this.tableLoading = true;
				_this._getApi('/wy/notice/getList', {
					residence_id: _this.communityId,
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						_this.tableData = baseData;
						_this.tableLoading = false;
					} else {

					}
				}).catch((err) => {});
			},
			
			objectSpanMethod(e) {
				// console.log(e)
				
			},

			/* * 发布新消息 */
			addBuild() {
				_this.addDialog = true;
			},

			/* * 选择某行 */
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},

			// 查看详情
			seeInfo(row) {
				_this.infopageParams = row;
				_this.infoDialog = true;
			},

			// _ ** 编辑
			editBuild() {
				var baseData = _this.selectTableRow;
				_this.infopageParams = baseData;
				if (!baseData) {
					_this.$alert('请先选择通知进行编辑,点击通知即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				_this.editDialog = true;
			},

			// _ ** 删除
			deleteBuild() {
				var baseData = _this.selectTableRow;
				if (!baseData) {
					_this.$alert('请先选择通知进行删除,点击通知即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('span', {
							style: 'color: teal'
						}, baseData.title + ' 吗？')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							var ids = [];
							ids.push(baseData.id);
							_this._postApi('/wy/notice/delete', {
								ids: ids
							}).then((res) => {
								if (res.code == 1) {
									_this.thisReload();
									_this.selectTableRow = null;
									_this.$message({
										message: '删除成功！',
										type: 'success',
										duration: 1000,
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								} else {
									_this.$message({
										message: res.msg,
										type: 'error',
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								}
							}).catch((err) => {});
						} else {
							done();
						}
					}
				}).then(action => {

				}).catch(() => {});
			},

			// _ *** 上传弹框事件消息
			addEvent(e) {
				switch (e.type) {
					case 'close':
						_this.addDialog = false;
						break;
					case 'add':
						_this.thisReload();
						_this.addDialog = false;
						break;
				}
			},
			/** --------> 方法介绍: 详情弹框事件消息
			author:郑凯 创建时间:2021-1-25 11:54:12 */
			infoEvent(e){
				switch (e.type) {
					case 'close':
						_this.infoDialog = false;
						break;
				}
			},
			/** --------> 方法介绍: 编辑弹框事件消息
			author:郑凯 创建时间:2021-1-25 12:20:28 */
			editEvent(e){
				switch (e.type) {
					case 'successful':
						_this.thisReload();
						_this.editDialog = false;
						break;
					case 'close':
						_this.editDialog = false;
						break;
				}
			}
		}
	}
</script>
