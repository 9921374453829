<style scoped>
	.cont {width: 100%;height: 100%;display: flex;flex-direction: column;}
	.article_title {background-color: #FcFcFc;padding: 10px 0;font-size: 1.2rem;font-weight: bold;color: #000;}
	.article_content {flex: 1;overflow: hidden;padding: 10px 0 0 5%;}
	.article_body {height: 100%;overflow-y: auto;}
	.title_content {display: flex;justify-content: space-between;}
	.close_icon img {width: 2rem;cursor: pointer;}
	.article_time {text-align: right;font-size: .9rem;font-weight: 400;}
</style>

<template>
	<div class="cont" solt="title">
		<div class="article_title">
			<div class="title_content">
				<div class="title_1">{{pageParams.title}}</div>
				<div class="close_icon" @click="closeDialog">
					<img src="../../assets/icon/cha.png" alt="">
				</div>
			</div>
			<div class="article_time">
				更新时间：{{pageParams.update_time}}
			</div>
		</div>
		<div class="article_content">
			<div class="article_body" v-html="pageParams.content"></div>
		</div>
	</div>
</template>

<script>
	var _this;

	export default {
		props: ['pageParams'],
		data() {
			return {

			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {

		},

		methods: {
			/** --------> 方法介绍: 关闭当前dialofg
			author:郑凯 创建时间:2021-1-25 11:50:23 */
			closeDialog() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},
		}
	}
</script>
